'use client';

import React, { useState } from 'react';
import FormCurrencyInputFancy from '@c/forms/controls/FormCurrencyInputFancy';
import FormLabel from '@c/forms/controls/FormLabel';
import BaseModal from '@c/modals/BaseModal';
import Button from '@ui/Button';
import { ProductDocument } from 'models/product';
import { Controller } from 'react-hook-form';
import { formatCurrency } from '@util/index';
import OffersToLikersIcon from './assets/offers-to-likers.svg';
import Image from 'next/image';
import { XMarkIcon } from '@heroicons/react/20/solid';
import SafeImage from '@ui/SafeImage';
import { FaHeart } from 'react-icons/fa';
import { useOffers } from '@c/dashboard/useOffers';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { sendOffersToLikers } from '@util/firestore/products';
import { useToastContext } from 'context/ToastContext';
import { useUserAddresses } from '@util/hooks/useUserAddresses';

export default function OffersToLikersModal({
  open,
  setOpen,
  product,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  product: ProductDocument;
}) {
  const addresses = useUserAddresses();
  const [offersToLikersLoading, setOffersToLikersLoading] = useState(false);
  const { showErrorToast } = useToastContext();
  const offerToLikersSchema = z.object({
    price: z
      .number({
        required_error: 'Offer price is required',
        invalid_type_error: 'Please enter a valid price',
      })
      .gte(1, 'Offer price cannot be lower than $1')
      .refine(
        (value) => value < product.price * 0.95,
        `Offer price must be lower than ${product.price * 0.95}`
      ),
  });

  type OffersToLikersModalFormData = z.infer<typeof offerToLikersSchema>;

  const {
    control,
    formState: { errors },
    setValue,
    watch,
    handleSubmit,
  } = useForm<OffersToLikersModalFormData>({
    resolver: zodResolver(offerToLikersSchema),
    defaultValues: {
      price: product.price * 0.85,
    },
  });

  const {
    selectedPercentage,
    setSelectedPercentage,
    getNewPrice,
    getNetEarnings,
  } = useOffers();

  const price = watch('price');

  const onSendOffers = async (price: number, product: ProductDocument) => {
    if (price && product && price <= product.price) {
      try {
        setOffersToLikersLoading(true);
        await sendOffersToLikers(product.id, price);
        setOpen(false);
      } catch (error) {
        showErrorToast((error as Error).message);
      } finally {
        setOffersToLikersLoading(false);
      }
    }
  };
  if (!product) {
    return null;
  }

  return (
    <BaseModal isOpen={open} dismiss={() => setOpen(false)} hideCloseIcon>
      <div className="flex flex-col gap-y-8 p-8">
        <div className="-mt-8 flex w-full items-center justify-between">
          <Image
            src={OffersToLikersIcon}
            alt="Offers To Likers"
            width={70}
            height={70}
          />

          <XMarkIcon
            className="h-12 w-12 cursor-pointer"
            onClick={() => {
              setOpen(false);
            }}
          />
        </div>

        <div className="-mt-8 flex flex-col gap-y-2">
          <div className={'flex w-full justify-between'}>
            <h1 className="flex items-center gap-[1.6rem] text-[2.5rem] font-semibold">
              Send offers to likers
            </h1>

            <span className="flex items-center gap-[0.8rem] text-[1.7rem]">
              <FaHeart className="h-8 w-8 text-zinc-600" />{' '}
              {product.favorite_count}
            </span>
          </div>

          <span className="max-w-[45rem] pt-2 text-[1.8rem] text-brand-dark-gray">
            We&apos;ll send an exclusive offer to the people who liked your
            item.
          </span>
        </div>

        <div className={'flex items-start gap-x-2'}>
          <SafeImage
            src={product.thumbnail}
            alt={product.title}
            height={300}
            width={300}
            className={'aspect-square h-32 w-32 rounded-2xl object-cover'}
          />

          <div className="flex flex-col gap-y-2 px-4 py-2">
            <h3
              className={
                'w-full max-w-[45rem] truncate text-[2rem] font-semibold'
              }
            >
              {product.title}
            </h3>

            <h3 className={'w-full text-[1.8rem] font-bold text-zinc-500'}>
              {formatCurrency(product.price)}
            </h3>
          </div>
        </div>

        <div className="grid w-full grid-cols-3 gap-x-4">
          {[10, 15, 20].map((percentage) => (
            <div
              className={`rounded-3xl border p-6 ${
                selectedPercentage === percentage
                  ? 'bg-black text-white'
                  : 'border-brand-primary text-black hover:border-brand-secondary hover:text-brand-secondary'
              } flex cursor-pointer items-center justify-center gap-x-2 text-[1.8rem] `}
              onClick={() => {
                setValue(
                  'price',
                  Number(getNewPrice(product.price, percentage))
                );
                setSelectedPercentage(percentage);
              }}
              key={percentage}
            >
              <div className="flex flex-col items-center">
                <h1 className={'text-[2rem] font-semibold'}>
                  {formatCurrency(
                    Number(getNewPrice(product.price, percentage))
                  )}
                </h1>

                <span className="text-[1.5rem]">{percentage}% OFF</span>
              </div>
            </div>
          ))}
        </div>

        <div className={'flex items-center justify-center gap-x-4 pt-3'}>
          <div className="h-px w-full bg-brand-lighter-gray" />

          <span className="text-[1.6rem] text-zinc-600">or</span>

          <div className="h-px w-full bg-brand-lighter-gray" />
        </div>

        <form
          onSubmit={handleSubmit((data) => onSendOffers(data.price, product))}
        >
          <div className="flex w-full justify-center gap-[1.6rem] p-3 text-center">
            <FormLabel
              value="New Price"
              required
              errorMessage={errors.price?.message}
            >
              <Controller
                control={control}
                name={'price'}
                render={({ field: { onBlur } }) => (
                  <div className="flex w-full justify-center">
                    <div className="my-4 max-w-[20rem]">
                      <FormCurrencyInputFancy
                        placeholder={'0.00'}
                        error={!!errors?.price}
                        onChange={(event) => {
                          setValue('price', Number(event.target.value));
                        }}
                        onBlur={onBlur}
                        value={price?.toString() ?? ''}
                        allowEmpty
                      />
                    </div>
                  </div>
                )}
              />
            </FormLabel>
          </div>

          <div className="flex w-[50rem] flex-col gap-y-2">
            <div
              className={
                'flex w-full items-center justify-between text-[1.8rem]'
              }
            >
              <h3>Net Earnings: </h3>
              <h3 className={'font-semibold text-green-700'}>
                {formatCurrency(
                  getNetEarnings({
                    price: price || 0,
                    shipping_cost: 0,
                    tax: 0,
                    total: price,
                    address: addresses[0],
                  })
                )}
              </h3>
            </div>

            <span className={'text-zinc-500'}>
              Offers are binding and must be at least 5% off the original price.
              If the buyer accepts, an order will be created and cannot be
              canceled.
            </span>
          </div>

          <div className="flex w-full justify-end gap-x-2 pt-8">
            <Button
              text="Cancel"
              onClick={() => setOpen(false)}
              type="tertiary"
            />

            <Button
              text={`${offersToLikersLoading ? 'Sending...' : 'Send Offers'}`}
              type="secondary"
              buttonType="submit"
              disabled={product.favorite_count <= 0 || offersToLikersLoading}
            />
          </div>
        </form>
      </div>
    </BaseModal>
  );
}
